$font-size-base: 14;
$font-family-base: 'Product Sans';
$navbar-padding-y: 1.55rem;

$border-radius: .5rem;
$border-radius-lg: .75rem;
$border-radius-sm: .25rem;

$body-bg: #171717;
$body-color: #fff;
$link-color: #fff;
$purple: #5f43f0;
$purple-primary: #5E1DED;
$violent-violet: #8100ff;
$artyclick-purple: #8600F0;
$green: #00cb56;
$green-corrected: rgb(140, 190, 80);
$green-bright: #5cff64;
$blue: rgb(81, 167, 216);
$overdue-blue: #4b00ff;
$orange-red: rgb(222, 85, 70);
$orange-yellow: rgb(232, 150, 61);
$purple-dark: rgb(89, 75, 231);
$purple-bright: rgb(123, 108, 237);
$yellow: #ffda00;
$dark-gray: #1d1f21;
$gray: #3b3a3c; //rgba(255, 255, 255, .15);
$lilac: #9d7acf;
$black: #000;
$bleached-cedar: #251d2f;
$crocus-purple: #9269F2;
$raisin-black: #27212D;
$blaze-orange: #FF6B00;
$purple-heart: #6F13A7;
$electric-purple: #BD00FF;
$magenta: #FF0099;
$dark-gunmetal: #251f2e;
$notification-color: $magenta;

$invalid-color: rgba(255, 200, 200, 1);

$box-shadow-sm: 0.2rem 0.2em 0.4rem rgba($black, 0.4);
$box-shadow: 0 .5rem 1rem rgba($black, 0.4);
$box-shadow-lg: 0 1rem 1rem rgba($black, 0.4);

$headings-font-weight: 400 !default;

$h1-font-size: $font-size-base * 2.65;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.7;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$headings-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1320px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1250px
);

$gradients: (
  orange-bright: (start: $orange-red,
    end: $orange-yellow,
    color: $body-color,
  ),
  purple-bright: (start: $purple-dark,
    end: $purple-bright,
    color: $body-color,
  ),
  purple-transparent: (start: rgba($purple, .05),
    end: rgba($purple, .15),
    color: $body-color,
  ),
  purple-bright-transparent: (start: rgba(#6229B3, .95),
    end: rgba(#6229B3, 0),
    color: $body-color,
  ),
  black-transparent: (start: rgba($black, .95),
    end: rgba($black, 0),
    color: $body-color,
  ),
  purple-dark-orange-bright: (start: #232030,
    end: #f0493c,
    color: $body-color,
  ),
  green-blue-bright: (start: $green-corrected,
    end: $blue,
    color: $body-color,
  ),
  blue-pink-bright: (start: #5a4bd9,
    end: #cd3f7b,
    color: #5a4bd9,
  ),
  violent-violet-overdue-blue: (start: $violent-violet,
    end: $overdue-blue,
    color: $violent-violet,
  ),
  artyclick-purple: (start: $artyclick-purple,
    end: $artyclick-purple,
    color: #FFFFFF,
  ),
);


// mobile color variable
$mobile-black-erie: #1D1D1D;
$mobile-black-jet: #343434;
$mobile-black-mist: #4F4F4F;
$mobile-black-olive: #3F3F40;
$mobile-black-galaxy: #262626;
$mobile-red-college: #FF007A;
$mobile-violet: #8600F0;
$mobile-violet-supreme: #5E1DED;
$mobile-black-mist: #3F3F40