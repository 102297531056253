@use 'sass:map';
@import 'variables';
@import 'fonts';
@import 'material';
@import '~bootstrap/scss/bootstrap';
@import '~@splidejs/splide/dist/css/splide-core.min.css';
@import '~@splidejs/splide/dist/css/splide.min.css';
@import '~@videogular/ngx-videogular/fonts/videogular.css';

body {
  width: 100%;
}

a {
  cursor: pointer;
}

label {
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

img {
  &.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.svg-styles {
  display: none;
}

.text-lilac {
  color: $lilac;
}

.text-black {
  color: $black;
}

.text-gray {
  color: $gray-500;
}

.text-orange {
  color: $orange-yellow;
}

.text-green-bright {
  color: $green-bright;
}

.text-error {
  color: red;
}

.bg-gray {
  background: $gray;
  color: white;
}

.bg-purple {
  background: #24093b;
  color: white;
}

.bg-purple-dark {
  background: $purple-dark;
  color: white;
}

.bg-white {
  background: white;
  color: black;
}

.flag {
  width: 1rem;
  height: 0.75rem;
}

.spinner-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;

  app-spinner {
    width: 2rem;
    height: 2rem;
  }
}

.arial {
  font-family: Arial;
}

hr {
  border-color: $gray;
}

@for $i from 1 through 10 {
  .w-fixed-#{$i} {
    width: #{$i + 'rem'};
  }
}

.mat-dialog-container {
  padding: 0 !important;
  overflow: hidden;
  background: transparent;
}

.prefix-plus {
  span:not(.content):before {
    content: '+ ';
  }

  h3:not(.content):before {
    content: '+ ';
  }
}

.prefix-min {
  h3:not(.content):before {
    content: '- ';
  }
}

.prefix-delete {
  span:not(.content):before {
    content: 'x ';
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.70);
}

.newlines {
  white-space: pre-wrap;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: map.get($grid-breakpoints, sm)) {
  .cc-theme-edgeless.cc-window {
    padding-bottom: 2.2em !important;
  }
}

#loading-x {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
  z-index: 99999;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  -ms-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  span {
    height: 60px !important;
    width: 60px !important;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    position: absolute;
  }
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-top-center .ngx-toastr:hover {
  margin-top: 20px;
  border-radius: 10px !important;
  box-shadow: none;
}

.toast-container .toast-title {
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 20px;
}

.toast-container .toast-message {
  font-size: 14px !important;
  font-weight: normal !important;
  margin-left: 20px;
}

.invoice-job-form-container {
  .form-control {
    padding: 0px 3px !important;

    .input-group {
      border: 0px solid transparent !important;
      color: #fff;
    }
  }
}